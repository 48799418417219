import Tabela from "./Tabela";
import Stylesheet from "reactjs-stylesheet";
import InputMask from 'react-input-mask';
import { Grid, TextField, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import "./StyleDate.css";

const Conciliacao = ({
    listaConciliacao,
    handleDateChange,
    startDate,
    endDate,
    toggleCalendar,
    loading,
    screenWidth,
}) => {

    return (
        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : styles.content}>
            <Grid sx={[screenWidth <= 1400 ? styles.customSeparatorSmallScreen : styles.customSeparator, { flexDirection: screenWidth <= 800 ? 'column' : 'row' }]}>
                <Grid>
                    <Typography sx={{
                        fontSize: '32px',
                        color: '#142A30',
                        fontWeight: 700,
                        display: 'flex',
                        fontFamily: 'Inter',
                        alignItems: 'center',
                        textDecoration: 'none'
                    }}>
                        Conciliação
                    </Typography>
                    <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>
                        Use esses recursos para conferir a conciliação da sua empresa.
                    </Typography>
                </Grid>
                <div style={{ height: 50, display: "flex", width: "30%", alignItems: 'center', justifyContent: 'flex-end' }}>
                    <span style={{ marginLeft: 10, fontSize: 20, marginLeft: 30, fontWeight: "bold" }} id="horas"></span>
                </div>
            </Grid>


            <Grid container spacing={0} style={{ width: '100%', display: "flex", flexDirection: 'column', paddingBottom: '25px', justifyContent: 'space-between' }}>
                <Grid style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingBottom: '15px'
                }}>

                    <Grid item container style={{
                        gap: '10px',
                        width: '95%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        flexWrap: screenWidth <= 1260 ? 'wrap' : 'nowrap',
                    }}>

                        <Grid item sx={styles.gridInput}>
                            <DatePicker
                                wrapperClassName="datePicker"
                                selected={startDate}
                                onChange={(dates) => handleDateChange(dates)}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                locale="pt"
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <InputMask
                                        style={{ width: '100%' }}
                                        placeholder="Período"
                                        type="text"
                                        mask="99/99/9999 - 99/99/9999"
                                        value={startDate && endDate
                                            ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                                            : startDate
                                                ? startDate.toLocaleDateString()
                                                : ''}
                                    >
                                        {() => (
                                            <TextField
                                                onClick={toggleCalendar}
                                                placeholder="Período"
                                                sx={{ ...styles.customInputNumber }}
                                            />
                                        )}
                                    </InputMask>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <div style={{ width: '100%', flexDirection: 'column', display: "flex" }}>
                    <Tabela
                        loading={loading}
                        rows={listaConciliacao}
                    />

                </div>
            </Grid>

        </Grid >
    );
};

export default Conciliacao;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '30px 25px'
    },
    contentMobile: {
        margin: '0px 10px'
    },
    customSeparator: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '23px'
    },
    customSeparatorSmallScreen: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px'
    },
    gridInput: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '30%',
        maxWidth: '20%',
        minWidth: '150px',
    },
    gridInputSmallScreen: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '50%',
        maxWidth: '100%',
        minWidth: '150px',
    },
    customInputNumber: {
        height: '45px',
        borderRadius: '15px',
        border: '1px solid #2F4650',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: '#142A30',
            fontWeight: 'bold',
            padding: '15px 17px',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
            color: '#142A30',
            fontWeight: 'bold',
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    bigPrices: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#999999',
    },
    modal: {
        width: '100%',
        maxWidth: '1100px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: 24,
        p: '65px 78px',
    },
    titleModal: {
        fontSize: '24px',
        fontWeight: 600
    },
    subTitleModal: {
        fontSize: '15px',
        fontWeight: 400
    },
    radioButton: {
        width: '100%',
        maxWidth: '280px',
        fontWeight: 500,
        color: '#3D3D3D',
        textTransform: 'none',
        backgroundColor: '#F1F1F1',
        fontSize: '20px',
        fontWeight: 600,
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-selected': {
            border: 'none',
            fontWeight: 600,
            borderRadius: '10px',
            backgroundColor: '#F2E2C4',
            '&:focus': {
                backgroundColor: '#F2E2C4',
                borderRadius: '10px',
            },
            '&:active': {
                backgroundColor: '#F2E2C4',
                borderRadius: '10px',
            }
        },
        '&:not(.Mui-selected)': {
            border: '0px solid #F2E2C4',
            borderRadius: '10px',
        },
    }
});