import { Button, Grid, LinearProgress, OutlinedInput, Typography } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import bigCheck from '../../assets/icons/bigCheck.png';
import logoEmpresaExemploScheng from '../../assets/logos/logoEmpresaExemploScheng.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faExclamation } from "@fortawesome/free-solid-svg-icons";

const Step4 = ({ logo, status, screenWidth }) => {

    const days = 3;

    return (
        <Grid sx={{
            bgcolor: screenWidth <= 950 ? '#FEFDF9' : '#F0EFEB',
            borderRadius: '30px',
            border: screenWidth <= 950 ? '1px solid #F0EFEB' : null,
        }}>

            <Grid sx={{ padding: screenWidth <= 950 ? '30px 20px 30px 20px' : '46px 50px 30px 50px' }}>
                <Grid sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: '30px',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>

                    <Grid sx={{ gap: '10px', display: 'flex', marginBottom: '30px' }}>
                        <img src={logo.img ? logo.img : logoEmpresaExemploScheng}
                            style={{
                                width: '80px',
                                height: '80px'
                            }}
                        />
                        <img src={bigCheck}
                            style={{
                                width: '80px',
                                height: '80px'
                            }}
                        />
                    </Grid>

                    <Grid sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }}>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '20px' : '24px', fontWeight: 700, color: '#142A30', marginBottom: '15px' }}>
                            Dados enviados com sucesso!
                        </Typography>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                            Agora nossa equipe de compliance irá revisar
                        </Typography>
                        <Typography sx={{ width: screenWidth <= 950 ? null : '45ch', fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099', paddingBottom: '15px' }}>
                            minuciosamente todas as informações recebidas e fornecerá um retorno em até 2 dias úteis.
                        </Typography>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                            #ID125
                        </Typography>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    marginBottom: '30px'
                }} />

                {status.every((i) => i.success === true) ?
                    <>
                        <Grid sx={{ bgcolor: '#FEFDF9', padding: '20px', borderRadius: '30px', marginBottom: '15px' }}>
                            <Grid sx={{
                                gap: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: '15px'
                            }}>
                                <Grid sx={{
                                    width: '30px',
                                    height: '30px',
                                    bgcolor: '#9DE200',
                                    borderRadius: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <FontAwesomeIcon icon={faCheck} style={{ fontSize: 17, color: "#17333A" }} />
                                </Grid>
                                <Typography sx={{ fontSize: screenWidth <= 950 ? '14px' : '16px', fontWeight: 700, color: '#142A30' }}>
                                    Retorno da equipe de compliance
                                </Typography>
                            </Grid>

                            <Grid sx={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                    Prezado João Gomes,
                                </Typography>
                                <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                    Todos os dados da empresa AUTO POSTO SCHUENG - RETA DO AEROPORTO foram analisados pela equipe de segurança. As informações foram verificadas e aprovadas pelo diretor. A partir deste momento, a empresa está autorizada a aceitar todas as formas de pagamento acordadas e a acessar todas as funcionalidades disponíveis no Xulis Parceiros. Esperamos que desfrute ao máximo dessas facilidades. Para qualquer dúvida, ajuda ou suporte necessário, o Xuxu estará à disposição para auxiliá-lo.
                                </Typography>
                                <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                    Atenciosamente,
                                </Typography>
                                <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                    Vitor.
                                </Typography>
                                <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                    <span style={{ fontWeight: 700 }}>Autorizado por:</span> Valdisnei Valério (Diretor de Compliance) e Eduardo Schueng (CEO)
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            bgcolor: '#17333A',
                            borderRadius: '30px',
                            padding: '20px',
                            marginTop: '15px',
                            flexDirection: screenWidth <= 950 ? 'column' : 'row'
                        }}>
                            <Typography sx={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700 }}>{'Tudo certo :)'}</Typography>
                            <Button sx={{
                                textTransform: 'none',
                                bgcolor: '#9DE200',
                                borderRadius: '20px',
                                '&:hover': { backgroundColor: '#9DE200' },
                                '&:active': { backgroundColor: '#9DE200' }
                            }}>
                                <Typography sx={{ color: '#142A30', fontSize: '13px', fontWeight: 700 }}>
                                    {'Acessar o Xulis Parceiros ->'}
                                </Typography>
                            </Button>
                        </Grid>
                    </> :
                    status.find((i) => i.error === true) ?
                        <>
                            <Grid sx={{
                                bgcolor: '#FEFDF9',
                                padding: '20px',
                                borderRadius: '30px',
                                marginBottom: '15px'
                            }}>
                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    paddingBottom: '15px'
                                }}>
                                    <Grid sx={{
                                        width: '30px',
                                        height: '30px',
                                        bgcolor: '#E57373',
                                        borderRadius: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <FontAwesomeIcon icon={faClose} style={{ fontSize: 17, color: "#FFFFFF" }} />
                                    </Grid>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '14px' : '16px', fontWeight: 700, color: '#142A30' }}>
                                        Retorno da equipe de compliance
                                    </Typography>
                                </Grid>

                                <Grid sx={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                        Prezado João Gomes,
                                    </Typography>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                        Aqui será descrito QUAL PONTO e PORQUE o contrato/cadastro foi falho e COMO atualizar para ser aprovado.
                                    </Typography>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                        Atenciosamente,
                                    </Typography>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                        Vitor.
                                    </Typography>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                        <span style={{ fontWeight: 700 }}>Autorizado por:</span> Valdisnei Valério (Diretor de Compliance) e Eduardo Schueng (CEO)
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                bgcolor: '#FEFDF9',
                                borderRadius: '30px',
                                padding: '20px',
                                marginTop: '15px',
                                flexDirection: screenWidth <= 950 ? 'column' : 'row'
                            }}>
                                <Typography sx={{ color: '#142A30', fontSize: '14px', fontWeight: 700 }}>{'Que pena :('}</Typography>
                                <Button sx={{
                                    textTransform: 'none',
                                    bgcolor: '#E57373',
                                    borderRadius: '20px',
                                    '&:hover': { backgroundColor: '#E57373' },
                                    '&:active': { backgroundColor: '#E57373' }
                                }}>
                                    <Typography sx={{ color: '#FFFFFF', fontSize: '13px', fontWeight: 700 }}>
                                        {'Acessar o Xulis Parceiros ->'}
                                    </Typography>
                                </Button>
                            </Grid>
                        </> :
                        <>
                            <Grid sx={{ bgcolor: screenWidth <= 950 ? '#F0EFEB' : '#FEFDF9', padding: '20px', borderRadius: '30px', marginBottom: '15px' }}>
                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    paddingBottom: '15px'
                                }}>
                                    <Grid sx={{
                                        width: '30px',
                                        height: '30px',
                                        bgcolor: '#FFB74D',
                                        borderRadius: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <FontAwesomeIcon icon={faExclamation} style={{ fontSize: 17, color: "#000000" }} />
                                    </Grid>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '14px' : '16px', fontWeight: 700, color: '#142A30' }}>
                                        Retorno da equipe de compliance
                                    </Typography>
                                </Grid>

                                <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                                    {screenWidth <= 950 ? 'Informações enviadas ainda em análise.' : 'Agora nossa equipe de compliance irá revisar'}
                                </Typography>
                            </Grid>

                            <Grid sx={{ bgcolor: screenWidth <= 950 ? '#F0EFEB' : '#FEFDF9', padding: '20px', borderRadius: '30px' }}>
                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                    paddingBottom: '15px'
                                }}>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '14px' : '16px', fontWeight: 700, color: '#142A30' }}>
                                        Prazo para retorno
                                    </Typography>
                                    <Grid sx={{
                                        bgcolor: '#9DE200',
                                        borderRadius: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Typography sx={{ fontSize: '11px', color: '#142A30', padding: '2px 8px' }}>
                                            {days > 2 ? 'Em atraso' : 'Dentro do prazo'}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ paddingBottom: '8px', display: 'flex' }}>

                                    {days <= 2 ?
                                        <>
                                            <Grid sx={{
                                                width: days == 2 ? '100%' : days == 1 ? '50%' : '0%',
                                                height: '9px',
                                                backgroundColor: '#17333A',
                                                borderRadius: days == 2 ? '20px' : null,
                                                borderTopLeftRadius: days >= 1 ? '20px' : null,
                                                borderBottomLeftRadius: days >= 1 ? '20px' : null
                                            }} />

                                            <Grid sx={{
                                                width: days == 2 ? '0%' : days == 1 ? '50%' : '100%',
                                                height: '9px',
                                                backgroundColor: '#142A304D',
                                                borderRadius: days == 0 ? '20px' : null,
                                                borderTopRightRadius: days >= 1 ? '20px' : null,
                                                borderBottomRightRadius: days >= 1 ? '20px' : null
                                            }} />
                                        </>
                                        :
                                        <>
                                            <Grid sx={{
                                                width: days <= 11 ? `${100 - (days - 2) * 10}%` : '10%',
                                                height: '9px',
                                                backgroundColor: '#17333A',
                                                borderTopLeftRadius: '20px',
                                                borderBottomLeftRadius: '20px'
                                            }} />

                                            <Grid sx={{
                                                width: days <= 11 ? `${(days - 2) * 10}%` : '90%',
                                                height: '9px',
                                                backgroundColor: '#E57373',
                                                borderTopRightRadius: '20px',
                                                borderBottomRightRadius: '20px'

                                            }} />
                                        </>
                                    }
                                </Grid>

                                <Grid sx={{
                                    gap: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row'
                                }}>
                                    <Grid sx={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', fontWeight: 700, color: '#142A30' }}>
                                            {days} dias úteis
                                        </Typography>
                                        {days > 2 ? <Typography sx={{ fontSize: '13px', fontWeight: 200, color: '#142A30' }}>
                                            ({days - 2} {(days - 2) <= 1 ? 'dia' : 'dias'} útil de atraso)
                                        </Typography> : null}
                                    </Grid>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', fontWeight: 700, color: '#142A30' }}>
                                        2 dias úteis
                                    </Typography>

                                </Grid>
                            </Grid>
                        </>
                }

                {/* <Grid sx={{ bgcolor: '#FEFDF9', padding: '20px', borderRadius: '30px', marginBottom: '15px' }}>
                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        paddingBottom: '15px'
                    }}>
                        <Grid sx={{
                            width: '30px',
                            height: '30px',
                            bgcolor: '#FFB74D',
                            borderRadius: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <FontAwesomeIcon icon={faExclamation} style={{ fontSize: 17, color: "#000000" }} />
                        </Grid>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A30' }}>
                            Retorno da equipe de compliance
                        </Typography>
                    </Grid>

                    <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                        Agora nossa equipe de compliance irá revisar
                    </Typography>
                </Grid>

                <Grid sx={{ bgcolor: '#FEFDF9', padding: '20px', borderRadius: '30px' }}>
                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        paddingBottom: '15px'
                    }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A30' }}>
                            Prazo para retorno
                        </Typography>
                        <Grid sx={{
                            bgcolor: '#9DE200',
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography sx={{ fontSize: '11px', color: '#142A30', padding: '2px 8px' }}>
                                {days > 2 ? 'Em atraso' : 'Dentro do prazo'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{ paddingBottom: '8px', display: 'flex' }}>

                        {days <= 2 ?
                            <>
                                <Grid sx={{
                                    width: days == 2 ? '100%' : days == 1 ? '50%' : '0%',
                                    height: '9px',
                                    backgroundColor: '#17333A',
                                    borderRadius: days == 2 ? '20px' : null,
                                    borderTopLeftRadius: days >= 1 ? '20px' : null,
                                    borderBottomLeftRadius: days >= 1 ? '20px' : null
                                }} />

                                <Grid sx={{
                                    width: days == 2 ? '0%' : days == 1 ? '50%' : '100%',
                                    height: '9px',
                                    backgroundColor: '#142A304D',
                                    borderRadius: days == 0 ? '20px' : null,
                                    borderTopRightRadius: days >= 1 ? '20px' : null,
                                    borderBottomRightRadius: days >= 1 ? '20px' : null
                                }} />
                            </>
                            :
                            <>
                                <Grid sx={{
                                    width: days <= 11 ? `${100 - (days - 2) * 10}%` : '10%',
                                    height: '9px',
                                    backgroundColor: '#17333A',
                                    borderTopLeftRadius: '20px',
                                    borderBottomLeftRadius: '20px'
                                }} />

                                <Grid sx={{
                                    width: days <= 11 ? `${(days - 2) * 10}%` : '90%',
                                    height: '9px',
                                    backgroundColor: '#E57373',
                                    borderTopRightRadius: '20px',
                                    borderBottomRightRadius: '20px'

                                }} />
                            </>
                        }
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'row'
                    }}>
                        <Grid sx={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A30' }}>
                                {days} dias úteis
                            </Typography>
                            {days > 2 ? <Typography sx={{ fontSize: '13px', fontWeight: 200, color: '#142A30' }}>
                                ({days - 2} {(days - 2) <= 1 ? 'dia' : 'dias'} útil de atraso)
                            </Typography> : null}
                        </Grid>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A30' }}>
                            2 dias úteis
                        </Typography>

                    </Grid>
                </Grid> */}

            </Grid>

        </Grid >
    )
}

export default Step4;

const styles = Stylesheet.create({
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            border: '2px solid #142A3066'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '12px',
            bgcolor: '#FEFDF999',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    }
})