const MenuClose = ({
    setMenuOpen,
    logo,
    bagOn,
    bagOff,
    marketingOn,
    marketingOff,
    dolarOn,
    dolarOff,
    reportOn,
    reportOff,
    sair,
    configuracoes,
    arrow,
    pageRef,
    urlLogo,
    irPage,
    onGroupContainer13Click,
}) => {


    return (
        <>
            <div style={{
                width: 50,
                backgroundColor: "#142A30",
                display: "flex",
                flexDirection: 'column',
                padding: "43px 12px 40px 12px",
                height: '100vh',
            }}>
                <div style={{ display: "flex", width: '100%', paddingBottom: '111px', position: 'relative', alignItems: 'center' }}>

                    <div>
                        <img src={logo} style={{ width: 25, height: 'auto', zIndex: 2 }} />
                    </div>

                    <div style={{
                        backgroundColor: '#5A8292',
                        width: '20px',
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: '38px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        cursor: 'pointer'
                    }}
                        onClick={() => setMenuOpen(true)}>
                        <img
                            src={arrow}
                            style={{
                                width: 7,
                                color: '#FFFFFF',
                                margin: '7px 6px 7px 18px'
                            }}
                        />
                    </div>

                </div>

                <div style={{
                    flex: 5,
                    width: '100%',
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>

                    <div style={{
                        display: "flex",
                        marginBottom: 18,
                        cursor: "pointer",
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                        onClick={() => irPage('xloja')}>
                        <img
                            src={pageRef == "xloja" ? bagOn : bagOff}
                            style={{ width: 15 }}
                        />
                    </div>

                    <div style={{
                        display: "flex",
                        width: '100%',
                        height: 15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: "pointer",
                        marginBottom: 18
                    }}
                        onClick={() => irPage('marketing')} id="menu_marketing">
                        <img
                            src={pageRef == "marketing" ? marketingOn : marketingOff}
                            style={{ width: 15 }} />
                    </div>

                    <div style={{
                        display: "flex",
                        width: '100%',
                        height: 18,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: "pointer",
                        marginBottom: 18
                    }}
                        onClick={() => irPage('recebiveis')} id="menu_recebiveis">
                        <img
                            src={pageRef == "recebiveis" ? dolarOn : dolarOff}
                            style={{ width: 15 }} />
                    </div>

                    <div style={{
                        display: "flex",
                        width: '100%',
                        height: 18,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: "pointer",
                        marginBottom: 18
                    }}
                        onClick={() => irPage('conciliacao')} id="menu_conciliacao">
                        <img
                            src={pageRef == "conciliacao" ? reportOn : reportOff}
                            style={{ width: 15 }} />
                    </div>

                </div>

                <div style={{ display: "flex", flexDirection: 'column' }}>

                    <div style={{
                        display: "flex",
                        width: '100%',
                        height: 15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: "pointer",
                        marginBottom: 15
                    }} id="menu_configuracoes" onClick={() => irPage('configuracoes')}>
                        <img src={configuracoes} style={{ width: 15, color: "#f1e2c4" }} />
                    </div>

                    <div style={{
                        display: "flex",
                        width: '100%',
                        height: 15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: "pointer",
                        marginBottom: 10
                    }} onClick={onGroupContainer13Click}>
                        <img src={sair} style={{ width: 15 }} />
                    </div>

                    <div style={{ borderTop: "1px solid #5A8292", width: 25, marginTop: 15 }}></div>

                </div>

                <div style={{ display: "flex", padding: "30px 12px 0px 12px", justifyContent: 'center' }}>

                    <div style={{ display: "flex", flex: 1, width: '100%', borderRadius: 50, width: 25, height: 25 }}>
                        <img src={urlLogo} style={{ objectFit: "cover", borderRadius: 30 }} />
                    </div>

                </div>
            </div >
        </>
    )
}

export default MenuClose;