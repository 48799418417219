import React, { useState } from "react";
import Stylesheet from "reactjs-stylesheet";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Collapse
} from "@mui/material";
import { format } from "date-fns";
import waiting from '../../../assets/waiting.jpg';
import visa from '../../../assets/visa.png';
import master from '../../../assets/master.png';
import elo from '../../../assets/elo_debito.png';
import hipercard from '../../../assets/hipercard.png';
import Pix from '../../../assets/Pix.png';
import amex from '../../../assets/amex.png';
import flagXulis from '../../../assets/flags/flagXulis.png';

const Tabela = ({ loading, rows }) => {

    const [openRows, setOpenRows] = useState([]);

    const columns = [
        { id: 'total', label: 'Total' },
        { id: 'tipo', label: 'Tipo' },
        { id: 'qtde', label: 'Quantidade' },
        // { id: 'idestabelecimento', label: 'idEstabelecimento' },
        { id: 'estabelecimento', label: 'Estabelecimento' },
        { id: 'dataconciliacao', label: 'Data Conciliação' },
        { id: 'data', label: 'Data' },
    ];

    const formatCurrency = (value, id) => {

        // Verifica se o valor é nulo ou indefinido
        if (value == null) {
            return 'R$ 0,00'; // Retorna um valor padrão
        }

        // Converte o valor em uma string, remove caracteres não numéricos e tenta convertê-lo em um número
        const numericValue = parseFloat(String(value).replace(/[^\d.-]/g, ''));

        // Verifica se o valor é um número válido
        if (!isNaN(numericValue)) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(numericValue);
        } else {
            // Retorna o valor original se não for um número válido
            return String(value);
        }
    };

    return (
        <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer className="table-container" xs={6} sx={{
                maxHeight: 959,
                overflowY: 'auto',
                direction: 'rtl',
                '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#5A8292',
                    borderRadius: '10px',
                    border: '1px solid transparent',
                    backgroundClip: 'content-box',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F0EFEB',
                    borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                },
            }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {columns.map((column) => (
                                <TableCell
                                    sx={{ fontSize: '15px', color: '#142A3099', textAlign: 'end' }}
                                    key={column.id}
                                    align="right"
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => {
                                                setOpenRows(prev =>
                                                    prev.includes(index)
                                                        ? prev.filter(rowIndex => rowIndex !== index)
                                                        : [...prev, index]
                                                );
                                            }}
                                        >
                                            {openRows.includes(index) ? '-' : '+'}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell sx={styles.textTable}>{formatCurrency(row.total)}</TableCell>
                                    <TableCell sx={styles.textTable}>{row.tipo == 'E' ? 'Excluido' : 'Inserido'}</TableCell>
                                    <TableCell sx={styles.textTable}>{row.qtde}</TableCell>
                                    {/* <TableCell sx={styles.textTable}>{row.idestabelecimento}</TableCell> */}
                                    <TableCell sx={styles.textTable}>{row.estabelecimento}</TableCell>
                                    <TableCell sx={styles.textTable}>{format(`${row.dataconciliacao}T00:00:00`, 'dd/MM/yyyy')}</TableCell>
                                    <TableCell sx={styles.textTable}>{format(`${row.data}T00:00:00`, 'dd/MM/yyyy')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                        <Collapse in={openRows.includes(index)} timeout="auto" unmountOnExit>
                                            <div style={{ margin: '16px' }}>
                                                <Typography variant="subtitle1" gutterBottom component="div">
                                                    Detalhes
                                                </Typography>
                                                <Table size="small" aria-label="details">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ paddingLeft: '10px' }}>Total</TableCell>
                                                            <TableCell>Bandeira</TableCell>
                                                            <TableCell>Tipo de Venda</TableCell>
                                                            <TableCell>Tipo</TableCell>
                                                            <TableCell>Data Conciliação</TableCell>
                                                            <TableCell>Data</TableCell>
                                                            <TableCell>Código de Autorização</TableCell>
                                                            <TableCell>ID</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.detalhe?.map((detail, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell>{formatCurrency(detail.total)}</TableCell>
                                                                <TableCell>
                                                                    {detail.bandeira ? (
                                                                        <>
                                                                            {detail.bandeira === 'XULIZ' || detail.bandeira === 'Xuliz' ? (
                                                                                <img src={flagXulis} style={{ width: 50, borderRadius: 4 }} alt="Bandeira XULIZ" />
                                                                            ) : detail.bandeira === 'Visa Crédito' || detail.bandeira === 'Visa Débito' ? (
                                                                                <img src={visa} style={{ width: 50, borderRadius: 4 }} alt="Visa" />
                                                                            ) : detail.bandeira === 'Master Crédito' || detail.bandeira === 'Master Débito' ? (
                                                                                <img src={master} style={{ width: 50, borderRadius: 4 }} alt="MasterCard" />
                                                                            ) : detail.bandeira === 'Elo Crédito' || detail.bandeira === 'Elo Débito' ? (
                                                                                <img src={elo} style={{ width: 50, borderRadius: 4 }} alt="Elo" />
                                                                            ) : detail.bandeira === 'Amex Crédito' || detail.bandeira === 'Amex Débito' ? (
                                                                                <img src={amex} style={{ width: 50, borderRadius: 4 }} alt="American Express" />
                                                                            ) : detail.bandeira === 'Hipercard Crédito' || detail.bandeira === 'Hipercard Débito' || detail.bandeira === 'Hiper Crédito' || detail.bandeira === 'Hiper Débito' || detail.bandeira == 'Hipercard' ? (
                                                                                <img src={hipercard} style={{ width: 50, borderRadius: 4 }} alt="Hipercard" />
                                                                            ) : detail.bandeira == 'PIX' || 'Pix' ? (
                                                                                <>
                                                                                    <img src={Pix} style={{ width: 50, borderRadius: 4 }} alt="Pix" />
                                                                                </>
                                                                            ) : (
                                                                                <p style={{ marginBottom: 0 }}>{detail.bandeira}</p>
                                                                            )}
                                                                        </>
                                                                    ) : null}
                                                                </TableCell>
                                                                <TableCell>{detail.tipovenda}</TableCell>
                                                                <TableCell>{detail.tipo == 'E' ? 'Excluido' : 'Inserido'}</TableCell>
                                                                <TableCell>{format(`${detail.dataconciliacao}T00:00:00`, 'dd/MM/yyyy')}</TableCell>
                                                                <TableCell>{format(`${detail.data}T00:00:00`, 'dd/MM/yyyy')}</TableCell>
                                                                <TableCell>{detail.codigoautorizacao}</TableCell>
                                                                <TableCell>{detail.id}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>

                {loading ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 30 }}>
                        <img src={waiting} style={{ width: 50, height: 50 }} alt="Waiting" />
                    </div>
                ) : (
                    rows.length === 0 ? (
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '20px'
                        }}>
                            <Typography sx={{
                                fontSize: '20px',
                                color: '#142A3099',
                                fontWeight: 700
                            }}>
                                Nenhum dado encontrado
                            </Typography>
                        </Grid>
                    ) : (
                        null
                    )
                )}
            </TableContainer>
        </Paper>
    );
};

export default Tabela;

const styles = Stylesheet.create({
    textTable: {
        color: '#142A30',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    taxaTable: {
        color: '#E95454',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    statusTable: {
        color: '#7FA800',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    actionTable: {
        fontSize: '18px',
        cursor: 'pointer',
    },
});
