import { useEffect, useState } from "react";
import Stylesheet from "reactjs-stylesheet";
import ModalUploadFile from "../Modals/ModalUploadFile";
import { Button, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Step1 = ({
    status,
    companyData,
    screenWidth,
    handleFieldChangeCompany,
    businessAddress,
    handleChangeBusinessAddress,
    getbusinessAddress,
    partner,
    handleChangePartner,
    addNewPartner,
    removePartner
}) => {

    const [modalSocialMei, setModalSocialMei] = useState(false);
    const [modalComprovanteEndereco, setModalComprovanteEndereco] = useState(false);
    const [modalComprovanteEnderecoPartner, setModalComprovanteEnderecoPartner] = useState(false);
    const [tdPatner, setIdPatner] = useState(0);
    const [modalFotoCNHRGPartner, setModalFotoCNHRGPartner] = useState(false);


    useEffect(() => {
        if (businessAddress?.cep?.length >= 8) {
            getbusinessAddress(businessAddress.cep)
        }
    }, [businessAddress.cep])

    const [modalState, setModalState] = useState({
        comprovanteEndereco: null, // Índice do sócio cujo modal está aberto
        fotoCNHRG: null, // Índice do sócio cujo modal está aberto
    });

    // Função para abrir modal
    const openModal = (field, index) => {
        setModalState((prevState) => ({ ...prevState, [field]: index }));
    };

    // Função para fechar modal
    const closeModal = (field) => {
        setModalState((prevState) => ({ ...prevState, [field]: null }));
    };

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>

            {status[0].error ?
                <Grid sx={{
                    bgcolor: '#F0EFEB',
                    borderRadius: '30px',
                    marginBottom: '30px',
                    border: '3px solid #E57373',
                    padding: '23px 25px 15px 25px'
                }}>
                    <Grid sx={{ bgcolor: '#FEFDF9', padding: '20px', marginBottom: '30px', borderRadius: '30px' }}>
                        <Grid sx={{
                            gap: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '15px',
                        }}>
                            <Grid sx={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: '#E57373'
                            }}>
                                <FontAwesomeIcon icon={faClose} style={{ fontSize: 24, color: "#FFFFFF" }} />
                            </Grid>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#142A30'
                            }}>
                                Explicação da inconsistência identificada
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099', paddingBottom: '15px' }}>
                                Sua conta não corresponde à empresa cadastrada e o Client ID não está ativo no sistema da Sicoob. Para resolver esse problema e garantir a aprovação da sua conta, é fundamental cadastrar uma conta bancária em nome da empresa e seguir o processo para gerar um novo Client ID.
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A3099' }}>
                                Se precisar de ajuda, entre em contato com o Xuxu, que irá guiá-lo em todas as etapas necessárias.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{
                            width: '100%',
                            borderRadius: '30px',
                            bgcolor: '#17333A',
                            '&:hover': { backgroundColor: '#142A30' },
                            '&:active': { backgroundColor: '#142A30' }
                        }}
                    >
                        <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '10px' }}>
                            Reenviar Documentos da Empresa e Sócios
                        </Typography>
                    </Button>
                </Grid> : null}

            <Grid sx={{
                bgcolor: screenWidth <= 950 ? '#FEFDF9' : '#F0EFEB',
                borderRadius: '30px',
                border: status[0].success ? '3px solid #9DE200' : status[0].error ? '3px solid #E57373' : screenWidth <= 950 ? '1px solid #F0EFEB' : null,
            }}>

                <ModalUploadFile
                    screenWidth={screenWidth}
                    modal={modalSocialMei}
                    close={() => setModalSocialMei(false)}
                    file={companyData.constratoSocialInscricaoMEI}
                    setFile={handleFieldChangeCompany}
                    fieldName='constratoSocialInscricaoMEI'
                />

                <ModalUploadFile
                    screenWidth={screenWidth}
                    modal={modalComprovanteEndereco}
                    close={() => setModalComprovanteEndereco(false)}
                    file={businessAddress.comprovanteEndereco}
                    setFile={handleChangeBusinessAddress}
                    fieldName='comprovanteEndereco'
                />

                <Grid sx={{ padding: screenWidth <= 950 ? '30px 20px 30px 20px' : '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '20px' : '24px', fontWeight: 700, color: '#142A30' }}>
                            Dados da empresa
                        </Typography>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                            A seguir você irá preencher todas as informações solicitadas em relação a sua empresa.
                        </Typography>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        {/* <TextField
                            label="*Razão social"
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            sx={{
                                '.css-10botns-MuiInputBase-input-MuiFilledInput-input': {
                                    paddingBottom: companyData.razaoSocial ? '8px' : '0px',
                                    margin: '0px 15px'
                                },
                                '.css-o943dk-MuiFormLabel-root-MuiInputLabel-root': {
                                    opacity: 1,
                                    color: '#142A3066',
                                    fontWeight: 'bold',
                                    margin: '0px 15px',
                                },
                                '.css-e4w4as-MuiFormLabel-root-MuiInputLabel-root': {
                                    opacity: 1,
                                    fontSize: '14px',
                                    color: '#142A3066',
                                    fontWeight: 'bold',
                                    margin: '0px 15px'
                                },
                                '& .MuiFilledInput-root': {
                                    fontSize: '14px',
                                    overflow: 'hidden',
                                    borderRadius: '30px',
                                    border: '2px solid #142A3066',
                                    backgroundColor: '#FEFDF999',
                                    borderColor: '#142A3066',
                                    '&:hover': {
                                        backgroundColor: '#FEFDF999',
                                    },
                                },
                            }}
                            value={companyData.razaoSocial}
                            onChange={(e) => handleFieldChangeCompany('razaoSocial', e.target.value)}
                        /> */}

                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Razão social"
                            value={companyData.razaoSocial}
                            onChange={(e) => handleFieldChangeCompany('razaoSocial', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Nome fantasia"
                            value={companyData.nomeFantasia}
                            onChange={(e) => handleFieldChangeCompany('nomeFantasia', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*CPF/CNPJ"
                            value={companyData.cpfCnpj}
                            onChange={(e) => handleFieldChangeCompany('cpfCnpj', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="Inscrição estadual"
                            value={companyData.inscricaoEstadual}
                            onChange={(e) => handleFieldChangeCompany('inscricaoEstadual', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: '100%',
                                maxWidth: '100%',
                            }}
                            type="text"
                            placeholder="*URL Site"
                            value={companyData.urlSite}
                            onChange={(e) => handleFieldChangeCompany('urlSite', e.target.value)}
                        />
                        <Grid
                            onClick={() => setModalSocialMei(true)}
                            sx={{
                                display: 'flex',
                                cursor: 'pointer',
                                bgcolor: '#FBFBF6',
                                alignItems: 'center',
                                borderRadius: '30px',
                                border: '2px solid #142A3066',
                                justifyContent: 'space-between',
                                flexBasis: screenWidth <= 950 ? '100%' : '49%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    width: '50%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                inputProps={{ readOnly: true }}
                                placeholder="+ Contrato social / Inscrição MEI"
                                value={
                                    companyData.constratoSocialInscricaoMEI?.[0]?.name
                                        ? `${companyData.constratoSocialInscricaoMEI[0].name.slice(0, 20)}...`
                                        : ''
                                }
                            />
                            <Grid sx={{
                                gap: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                {companyData.constratoSocialInscricaoMEI?.length > 1 ?
                                    <Typography sx={{
                                        fontSize: '14px',
                                        color: '#142A30',
                                        paddingTop: '3px',
                                        fontWeight: 'bold'
                                    }}>{`+ ${companyData.constratoSocialInscricaoMEI?.length - 1}`}
                                    </Typography> : null}
                                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: screenWidth <= 950 ? '0px 20px' : '0px 50px'
                }} />

                <Grid sx={{ padding: screenWidth <= 950 ? '30px 20px 30px 20px' : '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '20px' : '24px', fontWeight: 700, color: '#142A30' }}>
                            Endereço comercial
                        </Typography>
                    </Grid>

                    <Grid sx={{
                        gap: '10px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>

                        {screenWidth > 950 ? <Grid sx={{
                            ...styles.customInputNumber,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexBasis: '35%',
                            maxWidth: '49%',
                        }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '35%',
                                    maxWidth: '49%',
                                }}
                                type="text"
                                placeholder="*CEP"
                                value={businessAddress.cep}
                                onChange={(e) => handleChangeBusinessAddress('cep', e.target.value)}
                            />
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '35%',
                                    maxWidth: '49%',
                                }}
                                type="text"
                                placeholder="*Estado"
                                value={businessAddress.estado}
                                onChange={(e) => handleChangeBusinessAddress('estado', e.target.value)}
                            />
                        </Grid> : null}

                        {screenWidth <= 950 ?
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '100%',
                                    maxWidth: '100%',
                                }}
                                type="text"
                                placeholder="*CEP"
                                value={businessAddress.cep}
                                onChange={(e) => handleChangeBusinessAddress('cep', e.target.value)}
                            /> : null}

                        {screenWidth <= 950 ?
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    flexBasis: '100%',
                                    maxWidth: '100%',
                                }}
                                type="text"
                                placeholder="*Estado"
                                value={businessAddress.estado}
                                onChange={(e) => handleChangeBusinessAddress('estado', e.target.value)}
                            /> : null}

                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Endereço"
                            value={businessAddress.endereco}
                            onChange={(e) => handleChangeBusinessAddress('endereco', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Bairro"
                            value={businessAddress.bairro}
                            onChange={(e) => handleChangeBusinessAddress('bairro', e.target.value)}
                        />

                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Cidade"
                            value={businessAddress.cidade}
                            onChange={(e) => handleChangeBusinessAddress('cidade', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="*Complemento"
                            value={businessAddress.complemento}
                            onChange={(e) => handleChangeBusinessAddress('complemento', e.target.value)}
                        />
                        <OutlinedInput
                            sx={{
                                ...styles.customInputNumber,
                                flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}
                            type="text"
                            placeholder="Ponto de referência"
                            value={businessAddress.pontoReferencia}
                            onChange={(e) => handleChangeBusinessAddress('pontoReferencia', e.target.value)}
                        />
                        <Grid
                            onClick={() => setModalComprovanteEndereco(true)}
                            sx={{
                                display: 'flex',
                                cursor: 'pointer',
                                bgcolor: '#FBFBF6',
                                alignItems: 'center',
                                borderRadius: '30px',
                                border: '2px solid #142A3066',
                                justifyContent: 'space-between',
                                flexBasis: screenWidth <= 950 ? '100%' : '49%',
                                maxWidth: screenWidth <= 950 ? '100%' : '49%',
                            }}>
                            <OutlinedInput
                                sx={{
                                    ...styles.customInputNumber,
                                    width: '80%',
                                    '& .MuiOutlinedInput-input': {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '30px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    },
                                }}
                                type="text"
                                inputProps={{ readOnly: true }}
                                placeholder="+Comprovante de endereço"
                                value={
                                    businessAddress.comprovanteEndereco?.length >= 1
                                        ? `${businessAddress.comprovanteEndereco[0].name.slice(0, 20)}...`
                                        : ''
                                }
                            />
                            <Grid sx={{
                                gap: '4px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                {businessAddress.comprovanteEndereco?.length > 1 ?
                                    <Typography sx={{
                                        fontSize: '14px',
                                        color: '#142A30',
                                        paddingTop: '3px',
                                        fontWeight: 'bold'
                                    }}>{`+ ${businessAddress.comprovanteEndereco?.length - 1}`}
                                    </Typography> : null}
                                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    opacity: '0.7',
                    borderTopStyle: 'dashed',
                    borderTopWidth: '1.5px',
                    borderTopColor: '#142A3066',
                    margin: screenWidth <= 950 ? '0px 20px' : '0px 50px'
                }} />

                <Grid sx={{ padding: screenWidth <= 950 ? '30px 20px 30px 20px' : '30px 50px 30px 50px' }}>
                    <Grid sx={{ paddingBottom: '30px' }}>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '20px' : '24px', fontWeight: 700, color: '#142A30' }}>
                            Quadro societário
                        </Typography>
                        <Typography sx={{ fontSize: screenWidth <= 950 ? '13px' : '16px', color: '#142A3099' }}>
                            Adicione os dados cadastrais de todos os proprietários do estabelecimento.
                        </Typography>
                    </Grid>

                    {partner.map((i, index) => (
                        <>
                            {modalState.comprovanteEndereco === index && (
                                <ModalUploadFile
                                    screenWidth={screenWidth}
                                    modal={true}
                                    close={() => closeModal('comprovanteEndereco')}
                                    file={i.comprovanteEndereco}
                                    setFile={handleChangePartner}
                                    fieldName='comprovanteEndereco'
                                    useId={true}
                                    id={index}
                                />
                            )}

                            {modalState.fotoCNHRG === index && (
                                <ModalUploadFile
                                    screenWidth={screenWidth}
                                    modal={true}
                                    close={() => closeModal('fotoCNHRG')}
                                    file={i.fotoCNHRG}
                                    setFile={handleChangePartner}
                                    fieldName='fotoCNHRG'
                                    useId={true}
                                    id={index}
                                />
                            )}

                            <Grid sx={{
                                gap: '10px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                bgcolor: screenWidth <= 950 ? '#F0EFEB' : '#FEFDF9',
                                padding: '25px',
                                borderRadius: '30px',
                                marginBottom: '15px'
                            }}>

                                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A3099' }}>
                                    Sócio {index < 9 ? '0' + (index + 1) : index + 1}
                                </Typography>

                                {!index == 0 ? <FontAwesomeIcon onClick={() => removePartner(index)} icon={faClose} style={{ fontSize: 24, color: "#17333A", paddingRight: '15px' }} /> : null}

                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: '100%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Nome completo"
                                    value={i.nome}
                                    onChange={(e) => handleChangePartner(index, 'nome', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: '100%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*E-mail"
                                    value={i.email}
                                    onChange={(e) => handleChangePartner(index, 'email', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*CPF"
                                    value={i.cpf}
                                    onChange={(e) => handleChangePartner(index, 'cpf', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Data nascimento"
                                    value={i.dtNascimento}
                                    onChange={(e) => handleChangePartner(index, 'dtNascimento', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Celular 1 (DDD + N)"
                                    value={i.cell1}
                                    onChange={(e) => handleChangePartner(index, 'cell1', e.target.value)}
                                />
                                <OutlinedInput
                                    sx={{
                                        ...styles.customInputNumber,
                                        flexBasis: screenWidth <= 950 ? '100%' : '35%',
                                        maxWidth: '100%',
                                    }}
                                    type="text"
                                    placeholder="*Celular 2 (DDD + N)"
                                    value={i.cell2}
                                    onChange={(e) => handleChangePartner(index, 'cell2', e.target.value)}
                                />
                                <Grid
                                    onClick={() => openModal('comprovanteEndereco', index)}
                                    sx={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '2px solid #142A3066',
                                        borderRadius: '30px',
                                        bgcolor: '#FBFBF6',
                                        flexBasis: screenWidth <= 950 ? '100%' : '48.9%',
                                        maxWidth: screenWidth <= 950 ? '100%' : '48.9%',
                                    }}>
                                    <OutlinedInput
                                        sx={{
                                            ...styles.customInputNumber,
                                            flexBasis: '5%',
                                            maxWidth: '80%',
                                            '& .MuiOutlinedInput-input': {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                borderRadius: '30px',
                                                border: 'none',
                                                cursor: 'pointer'
                                            },
                                        }}
                                        type="text"
                                        inputProps={{ readOnly: true }}
                                        placeholder="+Comprovante de endereço"
                                        value={i.comprovanteEndereco?.[0]?.name}
                                    />
                                    <Grid sx={{
                                        gap: '4px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        {i.comprovanteEndereco?.length > 1 ?
                                            <Typography sx={{
                                                fontSize: '14px',
                                                color: '#142A30',
                                                paddingTop: '3px',
                                                fontWeight: 'bold'
                                            }}>{`+ ${i.comprovanteEndereco?.length - 1}`}
                                            </Typography> : null}
                                        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                                    </Grid>
                                </Grid>

                                <Grid
                                    key={index}
                                    onClick={() => openModal('fotoCNHRG', index)}
                                    sx={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '2px solid #142A3066',
                                        borderRadius: '30px',
                                        bgcolor: '#FBFBF6',
                                        flexBasis: screenWidth <= 950 ? '100%' : '48.9%',
                                        maxWidth: screenWidth <= 950 ? '100%' : '48.9%',
                                    }}>
                                    <OutlinedInput
                                        sx={{
                                            ...styles.customInputNumber,
                                            flexBasis: '5%',
                                            maxWidth: '80%',
                                            '& .MuiOutlinedInput-input': {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                borderRadius: '30px',
                                                border: 'none',
                                                cursor: 'pointer'
                                            },
                                        }}
                                        type="text"
                                        inputProps={{ readOnly: true }}
                                        placeholder="+Foto CNH ou RG"
                                        value={i.fotoCNHRG?.[0]?.name}
                                    />
                                    <Grid sx={{
                                        gap: '4px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        {i.fotoCNHRG?.length > 1 ?
                                            <Typography sx={{
                                                fontSize: '14px',
                                                color: '#142A30',
                                                paddingTop: '3px',
                                                fontWeight: 'bold'
                                            }}>{`+ ${i.fotoCNHRG?.length - 1}`}
                                            </Typography> : null}
                                        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 15, color: "#142A3099", paddingRight: '25px' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ))}

                    <Grid
                        onClick={() => addNewPartner()}
                        sx={{
                            gap: '10px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            bgcolor: screenWidth <= 950 ? '#F0EFEB' : '#FEFDF9',
                            padding: '20px',
                            borderRadius: '30px',
                            marginY: '15px',
                            cursor: 'pointer'
                        }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A3099' }}>
                            + Adicionar sócio
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    )
}

export default Step1;

const styles = Stylesheet.create({
    customInputNumber: {
        flexGrow: 1,
        flexShrink: 1,
        // minWidth: '340px',
        borderRadius: '15px',
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& .MuiOutlinedInput-input': {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '30px',
            border: '2px solid #142A3066',
            cursor: 'pointer'
        },
        '& .MuiInputBase-input': {
            color: '#142A30',
            fontWeight: 'bold',
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            padding: '12px',
            bgcolor: '#FEFDF999',
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            // fontSize: calcMimMax(12, 16, 100),
            color: '#142A3066',
            opacity: 1,
            fontWeight: 'bold'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    }
})    