import { useState } from 'react';
import { Modal, Grid, Typography, Button } from '@mui/material';

import Sicoob from '../../assets/bankLogos/Sicoob.png';
import closeCircle from '../../assets/icons/closeCircle.png';

function ConfiguracaoPix({ screenWidth, modal, close, set }) {

    const [selected, setSelected] = useState(0)

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                sx={{
                    top: '50%',
                    left: '50%',
                    width: '90%',
                    boxShadow: 24,
                    border: 'none',
                    maxWidth: '748px',
                    bgcolor: '#F0EFEB',
                    borderRadius: '30px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    padding: screenWidth <= 950 ? '30px' : '46px 49px'
                }}>
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Typography sx={{
                        fontWeight: 700,
                        color: '#142A30',
                        lineHeight: 1.1,
                        maxWidth: screenWidth <= 950 ? '20ch' : null,
                        fontSize: screenWidth <= 950 ? '20px' : '24px'
                    }}>
                        Qual instituição enviaremos o seu Pix?
                    </Typography>
                    <img
                        alt="Close"
                        onClick={close}
                        src={closeCircle}
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                </Grid>
                <Typography sx={{
                    paddingTop: '10px',
                    color: '#142A3099',
                    paddingBottom: '35px',
                    maxWidth: screenWidth <= 950 ? '27ch' : null,
                    fontSize: screenWidth <= 950 ? '14px' : '16px'
                }}>
                    {screenWidth <= 950 ?
                        <>Selecione a instituição bancária<br /> para enviarmos o seu Pix, de acordo com as taxas acordadas.</> :
                        `Selecione a instituição bancária para enviarmos o seu Pix, de acordo com as taxas acordadas.`}
                </Typography>

                <Grid
                    onClick={() => setSelected(selected == 1 ? 0 : 1)}
                    sx={{
                        gap: '10px',
                        width: '100%',
                        padding: '5px',
                        display: 'flex',
                        cursor: 'pointer',
                        borderRadius: '30px',
                        alignItems: 'center',
                        marginBottom: '35px',
                        justifyContent: 'flex-start',
                        bgcolor: selected == 1 ? '#5A8292' : '#FEFDF9'
                    }}>
                    <img
                        src={Sicoob}
                        onClick={close}
                        alt="Close"
                        style={{ width: '52px', height: '52px', cursor: 'pointer' }}
                    />
                    <Typography sx={{
                        color: selected == 1 ? '#FEFDF9' : '#142A30',
                        fontWeight: 700
                    }}>
                        Sicoob
                    </Typography>
                </Grid>

                <Button
                    onClick={() => { selected == 1 ? set('instituicao', 'Sicoob') : set('instituicao', ''); close() }}
                    sx={{
                        width: '100%',
                        borderRadius: '30px',
                        bgcolor: '#17333A',
                        '&:hover': { backgroundColor: '#142A30' },
                        '&:active': { backgroundColor: '#142A30' }
                    }}>
                    <Typography
                        sx={{
                            color: '#FFFFFF',
                            textTransform: 'none',
                            padding: '3px'
                        }}>
                        Escolher
                    </Typography>
                </Button>
            </Grid>
        </Modal>
    );
}

export default ConfiguracaoPix;
