import { useState } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import Logo_parceiros from '../../assets/logos/logo.parceiros.png';
import xLojaIcon from "../../assets/icons/xLojaIcon.png"
import meuMobileIcon from "../../assets/icons/meuMobileIcon.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import ModalAccess from "../../PrimeiroAcesso/Modals/ModalAccess";
import UserGroup from '../../assets/icons/userGroup.png';
import GearMenu from '../../assets/icons/gearMenu.png';
import CloseMenu from '../../assets/icons/closeMenu.png';
import { faArrowRight, faGear, faUserGroup, faXmark } from "@fortawesome/free-solid-svg-icons";

const MenuOnTopMobile = ({ calcMimMax, screenWidth, menuOpen, setMenuOpen }) => {

    const [modalAlertAccess, setModalAlertAccess] = useState(false);
    const menuPages = [
        { name: 'Início', url: 'Contas', },
        { name: 'Xloja', url: 'Configurações' },
        { name: 'Calculadora', url: 'Configurações' },
        { name: 'Recebíveis', url: 'Configurações' },
        { name: 'Relatório', url: 'Configurações' },
        { name: 'Produtos', url: 'Configurações' },
        { name: 'Marketing', url: 'Configurações' },
    ]

    const menuConfigs = [
        { icon: faGear, bgColor: '#0D2128', iconColor: '#9DE200', color: '#9DE200', name: 'Configurações', url: 'Configurações' },
        { icon: faUserGroup, bgColor: '#0D2128', iconColor: '#9DE200', color: '#9DE200', name: 'Contas', url: 'Contas' },
        { icon: faCircleXmark, bgColor: '#E57373', iconColor: '#FFFFFF', color: '#E57373', name: 'Sair', url: 'Sair' },
    ]

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <Grid sx={{
            zIndex: 3,
            display: 'flex',
            position: 'fixed',
            alignItems: 'center',
            justifyContent: menuOpen ? 'flex-start' : 'center',
            flexDirection: 'column',
            bgcolor: '#142A30',
            width: '100%',
            height: menuOpen ? '100vh' : null,
        }}>

            <ModalAccess screenWidth={screenWidth} modal={modalAlertAccess} close={() => setModalAlertAccess(false)} />

            <Grid sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '15px 30px',
                borderBottom: menuOpen ? '1px solid #5A8292' : null
            }}>

                <Grid>
                    <img src={Logo_parceiros} alt="Logo Parceiros" style={{ width: 100, height: 'auto' }} />
                </Grid>

                {!menuOpen ?
                    <Grid sx={{
                        display: 'flex',
                        gap: '26px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>

                        <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '7px', }}>

                            <Grid
                                onClick={() => setModalAlertAccess(true)}
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                    bgcolor: '#2F4650',
                                    borderRadius: '55px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Grid sx={{
                                    width: '22px',
                                    height: '22px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}>
                                    <Grid sx={{
                                        width: '11px',
                                        height: '11px',
                                        borderRadius: '20px',
                                        bgcolor: '#9DE200',
                                        position: 'absolute',
                                        border: '1px solid #2F4650',
                                        top: -1,
                                        right: 2.6
                                    }} />
                                    <FontAwesomeIcon icon={faBell} style={{ fontSize: 20, color: "#FFFFFF" }} />
                                </Grid>
                            </Grid>

                            <Grid
                                onClick={() => setModalAlertAccess(true)}
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                    bgcolor: '#2F4650',
                                    borderRadius: '55px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Grid sx={{
                                    width: '22px',
                                    height: '22px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <img src={xLojaIcon} style={{
                                        width: '16px',
                                        height: '16px',
                                        color: "#FFFFFF"
                                    }} />
                                </Grid>
                            </Grid>

                            <Button
                                onClick={() => setMenuOpen(true)}
                                sx={{ minWidth: '32px', textTransform: 'none', padding: 0 }}
                            >
                                <Grid sx={{
                                    width: '32px',
                                    height: '32px',
                                    bgcolor: '#2F4650',
                                    borderRadius: '55px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Grid sx={{
                                        width: '22px',
                                        height: '22px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <img src={meuMobileIcon} style={{
                                            width: '16px',
                                            height: '16px',
                                            color: "#FFFFFF"
                                        }} />
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid sx={{
                        display: 'flex',
                        gap: '26px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '7px', }}>
                            <Grid
                                onClick={() => setMenuOpen(false)}
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                    bgcolor: '#2F4650',
                                    borderRadius: '55px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer'
                                }}>
                                <Grid sx={{
                                    width: '22px',
                                    height: '22px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}>
                                    <FontAwesomeIcon icon={faXmark} style={{ fontSize: 20, color: "#FFFFFF" }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
            </Grid>


            {menuOpen ?
                <>
                    <Grid sx={{
                        width: '100%',
                        marginTop: '60px',
                        paddingBottom: '16px',
                        borderBottom: '1px solid #5A8292'
                    }}>
                        {menuPages.map((i) => (
                            <Grid
                                onClick={() => setModalAlertAccess(true)}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    padding: '15px 30px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                <Typography sx={{ color: '#5A8292', textAlign: 'start', fontWeight: 600, fontSize: '18px' }}>
                                    {i.name}
                                </Typography>
                                <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 20, color: "#5A8292" }} />
                            </Grid>))}
                    </Grid>

                    <Grid sx={{
                        width: '100%',
                        marginTop: '30px'
                    }}>
                        {menuConfigs.map((i) => (
                            <Grid
                                onClick={() => setModalAlertAccess(i.name != 'Sair' ? true : null)}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    padding: '15px 30px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                <Grid sx={{ gap: '3px', display: 'flex', alignItems: 'center', }}>
                                    <Grid sx={{
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50px',
                                        bgcolor: i.bgColor,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <FontAwesomeIcon icon={i.icon} style={{ fontSize: 13, color: i.iconColor }} />
                                    </Grid>
                                    <Typography sx={{ color: i.color, textAlign: 'start', fontWeight: 600, fontSize: '18px' }}>
                                        {i.name}
                                    </Typography>
                                </Grid>
                                <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 20, color: i.color }} />
                            </Grid>))}
                    </Grid>
                </> : null}
        </Grid>
    )
}

export default MenuOnTopMobile;