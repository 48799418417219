import { useState } from 'react';
import closeCircle from '../../assets/icons/closeCircle.png';
import { Modal, Grid, Typography, Button } from '@mui/material';

function ModalLojaAlugada({ screenWidth, modal, close, file, setFile, fieldName }) {

    const [tipe, setTipe] = useState(file ? file : '')

    const sendSelectTipe = () => {
        setFile(fieldName, tipe)
        close();
    };

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                sx={{
                    width: '90%',
                    maxWidth: '564px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '46px 49px',
                    borderRadius: '20px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '35px' }}>
                    <Typography sx={{ fontSize: screenWidth <= 950 ? '20px' : '24px', color: '#142A30', fontWeight: 700 }}>
                        Loja alugada?
                    </Typography>
                    <img
                        src={closeCircle}
                        onClick={close}
                        alt="Close"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                    />
                </Grid>

                <Grid sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: '35px',
                    justifyContent: 'space-between'
                }}>
                    <Button
                        onClick={() => setTipe('sim')}
                        sx={{
                            width: '49%',
                            bgcolor: tipe != 'sim' ? '#FEFDF9' : '#17333A',
                            borderRadius: '30px',
                            border: `2px solid  ${tipe != 'sim' ? '#142A3066' : '#FFFFFF'}`,
                            '&:hover': { backgroundColor: tipe != 'sim' ? '#FEFDF9' : '#17333A' },
                            '&:active': { backgroundColor: tipe != 'sim' ? '#FEFDF9' : '#17333A' }
                        }}
                    >
                        <Typography sx={{ color: tipe != 'sim' ? '#142A3066' : '#FFFFFF', fontWeight: 700, textTransform: 'none', padding: '3px' }}>
                            Sim
                        </Typography>
                    </Button>
                    <Button
                        onClick={() => setTipe('não')}
                        sx={{
                            width: '49%',
                            bgcolor: tipe != 'não' ? '#FEFDF9' : '#17333A',
                            borderRadius: '30px',
                            border: `2px solid  ${tipe != 'não' ? '#142A3066' : '#FFFFFF'}`,
                            '&:hover': { backgroundColor: tipe != 'não' ? '#FEFDF9' : '#17333A' },
                            '&:active': { backgroundColor: tipe != 'não' ? '#FEFDF9' : '#17333A' }
                        }}
                    >
                        <Typography sx={{ color: tipe != 'não' ? '#142A3066' : '#FFFFFF', fontWeight: 700, textTransform: 'none', padding: '3px' }}>
                            Não
                        </Typography>
                    </Button>
                </Grid>

                <Button
                    onClick={() => sendSelectTipe()}
                    sx={{
                        width: '100%',
                        borderRadius: '30px',
                        bgcolor: '#17333A',
                        '&:hover': { backgroundColor: '#142A30' },
                        '&:active': { backgroundColor: '#142A30' }
                    }}
                >
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '3px' }}>
                        Configurar
                    </Typography>
                </Button>
            </Grid>
        </Modal >
    );
}

export default ModalLojaAlugada;
