import { useState } from 'react';
import bigPlus from '../../assets/icons/bigPlus.png';
import closeCircle from '../../assets/icons/closeCircle.png';
import fileIconCircle from '../../assets/icons/fileIconCircle.png';
import { Modal, Grid, Typography, Button } from '@mui/material';

function ModalUploadFile({ screenWidth, modal, close, file, setFile, fieldName, useId, id }) {

    const [temporariFile, setTemporariFile] = useState(file ? file : []);

    const sendFile = () => {
        if (useId) {
            setFile(id, fieldName, temporariFile);
            close();
        } else {
            setFile(fieldName, temporariFile);
            close();
        }
    }

    // Manipulador para selecionar o arquivo via clique
    const handleFileSelect = (event) => {
        setTemporariFile((prevFiles) => [...prevFiles, event.target.files[0]]);
        // setTemporariFile(event.target.files[0]);
    };

    // dlimpa o campo
    const handleFileSelectClear = (index) => {
        setTemporariFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
        // setTemporariFile({});
    };

    // Manipulador para arrastar e soltar o arquivo
    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setTemporariFile(event.dataTransfer.files[0]);
        }
    };

    // Previne o comportamento padrão ao arrastar o arquivo sobre a área
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Função para abrir o seletor de arquivos ao clicar
    const openFileDialog = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <Modal open={modal} onClose={close}>
            <Grid
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                sx={{
                    width: '90%',
                    maxWidth: '748px',
                    top: '50%',
                    left: '50%',
                    boxShadow: 24,
                    border: 'none',
                    bgcolor: '#F0EFEB',
                    padding: '46px 49px',
                    borderRadius: '30px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                {screenWidth <= 950 ? null :
                    <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '35px' }}>
                        <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>
                            Faça o upload do arquivo
                        </Typography>
                        <img
                            src={closeCircle}
                            onClick={close}
                            alt="Close"
                            style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                        />
                    </Grid>}

                <Grid
                    sx={{
                        gap: '10px',
                        display: 'flex',
                        paddingX: '5px',
                        borderRadius: '10px',
                        marginBottom: '10px',
                        flexDirection: 'column',
                        maxHeight: temporariFile.length > 1 ? '150px' : 'auto',
                        overflowY: temporariFile.length > 1 ? 'auto' : 'visible',
                        '&::-webkit-scrollbar': {
                            width: '5px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            height: '42px',
                            borderRadius: '20px',
                            backgroundColor: '#17333A',
                            border: '1px solid trasparent',
                            backgroundClip: 'content-box',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#F0EFEB',
                            borderBottomLeftRadius: '20px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#17333A',
                        },
                    }}>
                    {temporariFile.map((file, index) => (
                        file.name ? (
                            <Grid
                                key={index}
                                sx={{
                                    display: 'flex',
                                    padding: screenWidth <= 950 ? '10px 30px' : '20px 30px',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderRadius: '30px',
                                    bgcolor: '#142A301A',
                                    justifyContent: 'space-between',
                                    marginBottom: '10px'
                                }}
                            >
                                <Grid
                                    sx={{
                                        gap: '15px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={fileIconCircle}
                                        onClick={close}
                                        alt="Close"
                                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                                    />
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '14px' : '15px', color: '#142A30', fontWeight: 600 }}>
                                        {file?.name?.length > 10
                                            ? `${file.name.slice(0, 10)}...`
                                            : file.name}
                                    </Typography>
                                    <Typography sx={{ fontSize: screenWidth <= 950 ? '14px' : '15px', color: '#142A3099' }}>
                                        {(file.size / 1000000).toFixed(1)}Mb
                                    </Typography>
                                </Grid>

                                <Button
                                    onClick={() => handleFileSelectClear(index)}
                                    sx={{
                                        borderRadius: '30px',
                                        '&:hover': { backgroundColor: 'none' },
                                        '&:active': { backgroundColor: 'none' }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            padding: '3px',
                                            fontWeight: 600,
                                            color: '#9E4848',
                                            textTransform: 'none',
                                            fontSize: screenWidth <= 950 ? '12px' : '16px'
                                        }}
                                    >
                                        Excluir
                                    </Typography>
                                </Button>
                            </Grid>
                        ) : null
                    ))}

                </Grid>

                <Grid
                    onClick={openFileDialog}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // padding: '10px',
                        borderStyle: 'dashed',
                        borderWidth: '2px',
                        borderColor: '#142A3066',
                        borderRadius: '30px',
                        marginBottom: '35px',
                        cursor: 'pointer'
                    }}>
                    <Grid sx={{
                        gap: '15px',
                        display: 'flex',
                        padding: screenWidth <= 950 ? '30px' : null,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: screenWidth <= 950 ? 'column' : 'row',
                    }}>
                        <img src={bigPlus} alt="Plus" style={{ width: '30px', height: '30px' }} />
                        {screenWidth <= 950 ?
                            <Grid>
                                <Typography sx={{ fontSize: '15px', color: '#7FA800', fontWeight: 700, marginRight: '4px' }}>
                                    Clique aqui <span style={{ fontSize: '15px', color: '#142A30', fontWeight: 700 }}>e busque pelo</span>
                                </Typography>
                                <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 700 }}>
                                    arquivo que você precisa.
                                </Typography>
                            </Grid>
                            :
                            <Grid>
                                <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 700 }}>
                                    Arraste seu arquivo ou
                                </Typography>
                                <Typography sx={{ fontSize: '15px', color: '#7FA800', fontWeight: 700 }}>
                                    clique para procurar
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                    />
                </Grid>


                <Grid sx={{
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {screenWidth <= 950 ?
                        <Button
                            onClick={close}
                            sx={{
                                width: '100%',
                                borderRadius: '30px',
                                bgcolor: 'transparent',
                                border: '2px solid #142A3099',
                                '&:hover': { backgroundColor: 'transparent' },
                                '&:active': { backgroundColor: 'transparent' }
                            }}
                        >
                            <Typography sx={{ color: '#142A3099', textTransform: 'none', padding: '3px', fontWeight: 600, fontSize: screenWidth <= 950 ? '15px' : null }}>
                                Cancelar
                            </Typography>
                        </Button> : null}
                    <Button
                        onClick={sendFile}
                        sx={{
                            width: '100%',
                            borderRadius: '30px',
                            bgcolor: '#17333A',
                            '&:hover': { backgroundColor: '#142A30' },
                            '&:active': { backgroundColor: '#142A30' }
                        }}
                    >
                        <Typography sx={{ color: '#FFFFFF', textTransform: 'none', padding: '3px', fontWeight: 600, fontSize: screenWidth <= 950 ? '15px' : null }}>
                            Enviar
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Modal >
    );
}

export default ModalUploadFile;
