import { useEffect, useState } from "react";
import Stylesheet from "reactjs-stylesheet";
import { Button, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronRight, faQuestion, faSearch, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import TabelaGroup from "./TabelaGroup";
import { addDays, format } from "date-fns";
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";

const Resumo = ({
    isMobile,
    screenWidth,
    rows,

    startDate,
    endDate,
    showCalendar,
    toggleCalendar,
    handleDateChange,
    clickAplicar,
    param,
    getDadosSQL,
    getDataInicial,
    getDataFinal,
    setStartDate,
    setEndDate,

    getPrimeiroDiaDoMes,
    getUltimoDiaDoMes
}) => {

    const today = new Date();
    const agora = format(new Date(), 'HH:mm');
    const hoje = format(today, 'yyyy-MM-dd');

    const [selectedBandeira, setSelectedBandeira] = useState('');
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState(param?.filter != 'Crédito parcelado' ? param?.filter : '');
    const [selectedFormaPagamentoParcelado, setSelectedFormaPagamentoParcelado] = useState(param?.filter == 'Crédito parcelado' ? param?.filter : '');

    const [filteredRows, setFilteredRows] = useState(null);
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedItemValor, setSelectedItemValor] = useState({})
    const [filters, setFilters] = useState([]);
    const [count, setCount] = useState(0);

    const [valorBrutoFilter, setValorBrutoFilter] = useState('');

    const [valuesFiltered, setValuesFiltered] = useState({
        XulizValor: 0,
        TaxaTotal: 0,
        CreditoValor: 0,
        CreditoParceladoValor: 0,
        PixValor: 0,
        DebitoValor: 0,
        Valor: 0,
        ValorBruto: 0,
        RecebidoAteHoje: 0,
        VouReceber: 0
    });

    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        let xulizTotal = 0;
        let taxaTotal = 0;
        let creditoVistaTotal = 0;
        let creditoParceladoTotal = 0;
        let pixTotal = 0;
        let debitoTotal = 0;
        let valorTotal = 0;
        let valorBrutoTotal = 0;
        let recebidoAteHoje = 0;
        let vouReceber = 0;

        rows?.forEach(day => {
            const diaSelecionado = format(addDays(day.datavencimento, 1), 'yyyy-MM-dd');
            const amanha = format(tomorrow, 'yyyy-MM-dd');

            valorTotal += parseFloat(day.valor)
            valorBrutoTotal += parseFloat(day.valorbruto)

            if (diaSelecionado == hoje && day.bandeira == 'PIX') {

            } else if (diaSelecionado == hoje && agora >= '15:10') {
                recebidoAteHoje += parseFloat(day.valor);
                taxaTotal += parseFloat(day.valor) - parseFloat(day.valorbruto);
            } else if (diaSelecionado < hoje) {
                recebidoAteHoje += parseFloat(day.valor);
                taxaTotal += parseFloat(day.valor) - parseFloat(day.valorbruto);
            } else if (diaSelecionado >= amanha) {
                vouReceber += parseFloat(day.valor);
            }

            if (day.bandeiraxuliz.split(' ')[0] == 'Xuliz') {
                xulizTotal += parseFloat(day.valor);
            } else if (day.bandeiraxuliz.split(' ')[1] == 'Crédito') {
                if (day.nrtotalparcelas > 1) {
                    creditoParceladoTotal += parseFloat(day.valor);
                } else if (day.nrtotalparcelas <= 1) {
                    creditoVistaTotal += parseFloat(day.valor);
                }
            } else if (day.bandeiraxuliz.split(' ')[1] == 'Débito') {
                debitoTotal += parseFloat(day.valor);
            } else if (day.bandeiraxuliz == 'Pix' && diaSelecionado != hoje) {
                pixTotal += parseFloat(day.valor);
            }

            setValuesFiltered({
                XulizValor: xulizTotal,
                TaxaTotal: taxaTotal,
                CreditoValor: creditoVistaTotal,
                CreditoParceladoValor: creditoParceladoTotal,
                PixValor: pixTotal,
                DebitoValor: debitoTotal,
                Valor: valorTotal,
                ValorBruto: valorBrutoTotal,
                RecebidoAteHoje: recebidoAteHoje,
                VouReceber: vouReceber
            });

        });

    }, [filteredRows]);

    const bandeiraMap = {
        "Xuliz": ["Xuliz"],
        "Visa": ["Visa Crédito", "Visa Débito"],
        "Master": ["Master Crédito", "Master Débito"],
        "Elo": ["Elo Crédito", "Elo Débito"],
        "Hipercard": ["Hipercard Crédito", "Hipercard Débito", "Hiper Crédito", "Hiper Débito"],
        "Amex": ["Amex Crédito", "Amex Débito"]
    };

    const tipoLançamento = [
        { label: "Débito", filterValue: 'Débito', value: valuesFiltered?.DebitoValor ? valuesFiltered.DebitoValor : 'R$0,00' },
        { label: "Crédito parcelado", filterValue: 'Crédito parcelado', value: valuesFiltered?.CreditoParceladoValor ? valuesFiltered.CreditoParceladoValor : 'R$0,00' },
        { label: "Crédito à vista", filterValue: 'Crédito', value: valuesFiltered?.CreditoValor ? valuesFiltered?.CreditoValor : 'R$0,00' },
        { label: "Xulis App", filterValue: 'Xuliz', value: valuesFiltered?.XulizValor ? valuesFiltered.XulizValor : 'R$0,00' },
        { label: "Pix", filterValue: 'Pix', value: valuesFiltered?.PixValor ? valuesFiltered.PixValor : 'R$0,00' },
    ]

    const handleBandeiraChange = (event) => {
        if (event.target.value == 'bandeira') {
            removeFilter('Bandeira');
        } else {
            filtersTitle('Bandeira', event.target.value);
        }
        setSelectedBandeira(event.target.value);
    };

    const handleFormaPagamentoChange = (eventOrItem) => {

        const isEvent = eventOrItem.target !== undefined;
        const selectedValue = isEvent ? eventOrItem.target.value : eventOrItem.filterValue;
        const selectedLabel = isEvent ? eventOrItem.target.options[eventOrItem.target.selectedIndex].text : eventOrItem.label;

        const isParcelado = selectedLabel === 'Crédito parcelado';
        const currentFormaPagamento = isParcelado ? selectedFormaPagamentoParcelado : selectedFormaPagamento;

        // Atualiza o estado com os valores padrão
        setSelectedFormaPagamentoParcelado('formapagamentoparcelado');
        setSelectedFormaPagamento('formapagamento');

        if (selectedValue === currentFormaPagamento) {
            removeFilter('formapagamento');
        } else {
            if (selectedValue) {
                filtersTitle('formapagamento', selectedValue);
            } else {
                removeFilter('formapagamento');
            }

            // Define o valor correto com base no tipo de pagamento
            isParcelado
                ? setSelectedFormaPagamentoParcelado(selectedValue)
                : setSelectedFormaPagamento(selectedValue);
        }
    };

    const applyFilters = () => {
        let filtered = rows;

        if (selectedBandeira && selectedBandeira !== 'bandeira') {
            const bandeiraOptions = bandeiraMap[selectedBandeira] || [];
            filtered = filtered.filter(i =>
                bandeiraOptions.some(option => option.toLowerCase() === i?.bandeiraxuliz?.toLowerCase())
            );
        }

        // console.log('filtered', filtered)

        if (selectedFormaPagamento && selectedFormaPagamento.toLowerCase() !== 'formapagamento') {
            filtered = filtered.filter(item => {
                // Divide a forma de pagamento em partes
                const formaPagamentoSplit = item.bandeiraxuliz?.toLowerCase().split(' ');
                // Extrai a forma de pagamento, considerando diferentes formatos
                const formaPagamento = formaPagamentoSplit.length > 1 ? formaPagamentoSplit[1] : formaPagamentoSplit[0];

                // Condição para verificar a forma de pagamento selecionada
                if (selectedFormaPagamento.toLowerCase() === 'xuliz') {
                    return formaPagamento === 'xuliz';
                } else if (selectedFormaPagamento.toLowerCase() === 'débito') {
                    return formaPagamento === 'débito';
                } else if (selectedFormaPagamento.toLowerCase() === 'pix' && item.datavencimento != hoje) {
                    return formaPagamento === 'pix';
                } else if (selectedFormaPagamento.toLowerCase() === 'crédito') {
                    return formaPagamento === 'crédito' && Number(item.nrtotalparcelas) === 1;
                }
            });
        }

        if (selectedFormaPagamentoParcelado && selectedFormaPagamentoParcelado.toLowerCase() === 'crédito parcelado') {
            filtered = filtered.filter(item => {
                // Divide a forma de pagamento em partes
                const formaPagamentoSplit = item.bandeiraxuliz?.toLowerCase().split(' ');
                // Extrai a forma de pagamento, considerando diferentes formatos
                const formaPagamento = formaPagamentoSplit.length > 1 ? formaPagamentoSplit[1] : formaPagamentoSplit[0];
                // Verifica se a forma de pagamento é 'crédito' e o número de parcelas é maior que 1
                return formaPagamento === 'crédito' && Number(item.nrtotalparcelas) > 1;
            });
        }

        setFilteredRows(filtered);
    };

    const filtersTitle = (name, data) => {
        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            const existingFilterIndex = newFilters.findIndex(filter => filter.type === name);

            if (existingFilterIndex !== -1) {
                newFilters[existingFilterIndex].value = data;
            } else {
                newFilters.push({ type: name, value: data });
            }

            return newFilters;
        });
    };

    const removeFilter = async (name) => {
        let updatedFilters = filters.filter(filter => filter.type !== name);

        setFilters(updatedFilters);

        if (name === 'Bandeira') {
            setSelectedBandeira('bandeira');
        } else if (name === 'formapagamento') {
            setSelectedFormaPagamento('formapagamento')
            setSelectedFormaPagamentoParcelado('formapagamento')
        } else if (name === 'periodo') {
            const primeiroDia = format(getPrimeiroDiaDoMes(), 'yyyy-MM-dd');
            const ultimoDia = format(getUltimoDiaDoMes(), 'yyyy-MM-dd');
            getDadosSQL(true, primeiroDia, ultimoDia);
            setStartDate(new Date(getPrimeiroDiaDoMes()));
            setEndDate(new Date(getUltimoDiaDoMes()));
        }

        applyFilters();
    };

    useEffect(() => {
        applyFilters();
    }, [selectedBandeira, selectedFormaPagamento, selectedFormaPagamentoParcelado, startDate, endDate, rows]);

    useEffect(() => {
        if (param?.filter) {
            filtersTitle('formapagamento', param.filter)
        }
        if (startDate && endDate) {
            filtersTitle('periodo', `${format(new Date(startDate), 'dd/MM/yyyy')} - ${format(new Date(endDate), 'dd/MM/yyyy')}`);
        }

    }, [startDate, endDate, param])

    const bigValues = new Map();

    rows.forEach((row) => {
        const key = JSON.stringify([row.datavencimento, row.bandeira, row.lancamento]);
        const valor = parseFloat(row.valor);
        const valorbruto = parseFloat(row.valorbruto);
        if (bigValues.has(key)) {
            const group = bigValues.get(key);
            group.valor += valor;
            group.valorbruto += valorbruto;
            group.count += 1;
            group.items.push(row);
        } else {
            bigValues.set(key, {
                datavencimento: row.datavencimento,
                bandeira: row.bandeira,
                lancamento: row.lancamento,
                valor: valor,
                valorbruto: valorbruto,
                count: 1,
                items: [row]
            });
        }
    });

    function filtersSelect() {
        return (
            // <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item container style={{
                gap: '10px',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
            }}>

                <Grid item sx={styles.gridInput}>
                    <DatePicker
                        wrapperClassName="datePicker"
                        selected={startDate}
                        onChange={(dates) => handleDateChange(dates)}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        locale="pt"
                        dateFormat="dd/MM/yyyy"
                        customInput={
                            <InputMask
                                style={{ width: '100%' }}
                                placeholder="Período"
                                type="text"
                                mask="99/99/9999 - 99/99/9999"
                                value={startDate && endDate
                                    ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                                    : startDate
                                        ? startDate.toLocaleDateString()
                                        : ''}
                            >
                                {() => (
                                    <TextField
                                        onClick={toggleCalendar}
                                        placeholder="Período"
                                        sx={{ ...styles.customInputNumber }}
                                    />
                                )}
                            </InputMask>
                        }
                    />
                </Grid>

                <Grid item sx={styles.gridInput}>
                    <TextField
                        select
                        variant="outlined"
                        sx={[styles.customInputNumber]}
                        value={selectedBandeira}
                        onChange={handleBandeiraChange}
                        InputProps={{
                            id: "qual_bandeira",
                        }}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="bandeira">Bandeira</option>
                        <option value="Xuliz">Xulis</option>
                        <option value="Visa">Visa</option>
                        <option value="Master">Master</option>
                        <option value="Elo">Elo</option>
                        <option value="Hipercard">Hipercard</option>
                        <option value="Amex">Amex</option>
                    </TextField>
                </Grid>

                <Grid item sx={styles.gridInput}>
                    <TextField
                        select
                        variant="outlined"
                        sx={[styles.customInputNumber]}
                        value={
                            selectedFormaPagamento == 'formapagamento' ? selectedFormaPagamentoParcelado :
                                selectedFormaPagamento == '' ? selectedFormaPagamentoParcelado : selectedFormaPagamento}
                        onChange={handleFormaPagamentoChange}  // Continua usando o onChange
                        InputProps={{
                            id: "forma_pagamento",
                        }}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="">Tipo de lançamento</option>
                        {tipoLançamento.map((tipo, index) => (
                            <option key={index} value={tipo.filterValue}>
                                {tipo.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>

            </Grid>
            // </Grid>
        )
    }

    const formatCurrency = (value) => {
        const valueStr = String(value);
        const cleanedValue = valueStr.replace(/[^\d.-]/g, '');
        const numberValue = parseFloat(cleanedValue);

        if (isNaN(numberValue)) {
            return '';
        }
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(numberValue);
    };

    const handleValorBruto = (event) => {
        // Converte o valor do input para o formato numérico esperado
        // const valorBrutoInput = parseCurrency(event.target.value);
        const valorBrutoInput = event.target.value;
        setValorBrutoFilter(valorBrutoInput);

        // Filtra os itens com base no valor bruto
        let filteredItems = selectedItem?.items?.filter(item =>
            item?.valorbruto === valorBrutoInput.replace(',', '.')
        ) || [];

        // Se não encontrar pelo valor bruto, tenta encontrar por "idvenda"
        if (filteredItems.length === 0) {
            filteredItems = selectedItem?.items?.filter(item =>
                item?.idvenda === valorBrutoInput
            ) || [];
        }

        // Atualiza o estado com os itens filtrados ou com os itens originais
        setSelectedItemValor({
            items: filteredItems.length > 0 ? filteredItems : selectedItem?.items || []
        });
    };

    const parseCurrency = (value) => {
        const cleanedValue = value.replace(/[^\d]/g, '');
        return parseFloat(cleanedValue) / 100;
    };

    useEffect(() => {
        if (!param?.dtIni && !param?.dtend) {
            const primeiroDia = format(getPrimeiroDiaDoMes(), 'yyyy-MM-dd');
            const ultimoDia = format(getUltimoDiaDoMes(), 'yyyy-MM-dd');
            getDadosSQL(true, primeiroDia, ultimoDia);
            setStartDate(new Date(getPrimeiroDiaDoMes()));
            setEndDate(new Date(getUltimoDiaDoMes()));
            return;
        }
    }, []);

    useEffect(() => {
        if (!param?.dtIni && !param?.dtend) {
            verificarEObterDados(); // Função que você quer executar
            return;
        }

        let checkCount = 0;
        const maxChecks = 2;

        function checkRows() {
            // Precisamos obter o valor atual de rows a cada verificação
            if (rows.length === 0 && checkCount < maxChecks) {
                checkCount++;
                setTimeout(checkRows, 1000); // Faz a próxima verificação após 1 segundo
            } else if (rows.length === 0 && checkCount === maxChecks) {
                verificarEObterDados(); // Chama a função se rows ainda estiver vazio após 3 tentativas
            } else {
                // console.log('rows já contém dados:', rows); // Quando rows não está vazio
            }
        }

        // Inicia a verificação
        const timer = setTimeout(checkRows, 1000); // Primeira verificação após 1 segundo

        return () => {
            clearTimeout(timer); // Limpa o timeout quando o componente for desmontado
        };
    }, [rows, param?.dtIni, param?.dtend]);

    function verificarEObterDados() {
        // Verifica se o array está vazio e faz a chamada para obter os dados do SQL
        // Pega o mes inteiro e muda a data para o novo periodo
        setCount(count + 1)
        if (rows.length <= 0) {
            const primeiroDia = format(getPrimeiroDiaDoMes(), 'yyyy-MM-dd');
            const ultimoDia = format(getUltimoDiaDoMes(), 'yyyy-MM-dd');
            getDadosSQL(true, primeiroDia, ultimoDia);
            setStartDate(new Date(getPrimeiroDiaDoMes()));
            setEndDate(new Date(getUltimoDiaDoMes()));
        }
    }

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;

        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated < min) {
            return min;
        } else if (calculated > max) {
            return max;
        }

        return 16;
    }

    function selectedFilter() {
        return (
            <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                {isMobile ? null : <Button onClick={() => history.back()}>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{ fontSize: '16px', color: "#999999", cursor: 'pointer', paddingRight: '3px' }}
                    />
                    <Typography style={{ fontSize: '13px', color: '#999999', textTransform: 'none' }}>
                        Voltar
                    </Typography>
                </Button>}

                {filters.length > 0 ?
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? null : 'center',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                        paddingBottom: isMobile ? '10px' : null
                    }}>
                        <Typography sx={{ width: '16ch', color: '#142A3099', fontSize: '15px', fontWeight: 600, paddingRight: '10px' }}>
                            Filtros selecionados:
                        </Typography>

                        <Grid sx={{ display: 'flex', flexDirection: isMobile ? 'row' : null, width: isMobile ? '100%' : null }}>
                            {filters.map((filter, index) => (
                                <Grid
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: "3px 10px",
                                        marginBottom: isMobile ? '5px' : null,
                                        borderRadius: '7px',
                                        backgroundColor: '#9DE200',
                                        marginRight: '10px',
                                        alignItems: 'center',
                                        justifyContent: isMobile ? 'space-between' : null
                                    }}
                                >
                                    <Typography sx={{ color: '#142A30', fontSize: '10px', fontWeight: 600, marginRight: '10px' }}>
                                        {filter.value == "Xuliz" ? "Xulis" : filter.value}
                                    </Typography>
                                    <FontAwesomeIcon
                                        icon={faX}
                                        style={{ fontSize: 10, color: "#262626", cursor: 'pointer' }}
                                        onClick={() => removeFilter(filter.type)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid> : null
                }
            </Grid>
        )
    }


    console.log('filteredRows', filteredRows)

    if (filteredRows) {
        // Filtra itens com idvenda válido e extrai todos os idvenda
        const validRows = filteredRows.filter(item => item.idvenda !== null && item.idvenda !== undefined);
        const idvendaList = validRows.map(item => item.idvenda);

        // Encontra os IDs duplicados
        const duplicates = idvendaList.filter((id, index, array) => array.indexOf(id) !== index);

        // Remove duplicatas da lista de IDs repetidos
        const uniqueDuplicates = [...new Set(duplicates)];

        if (uniqueDuplicates.length > 0) {
            // Filtra os itens correspondentes aos IDs duplicados
            const duplicateItems = validRows.filter(item => uniqueDuplicates.includes(item.idvenda));

            // Cria um array com os detalhes dos itens duplicados
            const duplicateDetails = duplicateItems.map(item => ({
                idvenda: item.idvenda,
                codigoautorizacao: item.codigoautorizacao,
                bandeira: item.bandeira,
                valorbruto: item.valorbruto,
                nrtotalparcelas: item.nrtotalparcelas,
                datestamp: item.datestamp,
            }));

            console.log('Itens duplicados:', duplicateDetails);
        } else {
            console.log('Nenhum ID repetido encontrado.');
        }
    }



    return (
        <Grid item xs sx={isMobile ? styles.contentMobile : screenWidth <= 1500 ? styles.contentSmallScreen : styles.content}>

            <Grid container spacing={0} style={{ width: '100%', display: "flex", flexDirection: 'column' }}>

                <Grid sx={{ paddingBottom: isMobile ? '20px' : '35px', width: '100%' }}>

                    {isMobile ? null : selectedFilter()}

                    <Grid style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'flex-start' : 'flex-end',
                        justifyContent: 'space-between'
                    }}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', width: '35%' }}>

                            <Grid sx={{
                                display: 'flex',
                                alignItems: 'self-start',
                                flexDirection: 'column',
                                paddingBottom: isMobile ? '20px' : null
                            }}>
                                <Typography style={{ fontSize: '32px', color: '#142A30', fontWeight: 700 }}>
                                    Resumo
                                </Typography>
                                <Grid sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: '5px',
                                    backgroundColor: '#9DE200',
                                    padding: '5px 10px',
                                    borderRadius: '10px'
                                }}>
                                    <Typography style={{ fontSize: '10px', color: '#142A30' }}>
                                        Recebíveis
                                    </Typography>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        style={{ fontSize: '10px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                    />
                                    <Typography style={{ fontSize: '10px', color: '#142A30', fontWeight: 700 }}>
                                        Resumo
                                    </Typography>
                                    {selectedItem?.items?.length > 0 ?
                                        <>
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                style={{ fontSize: '10px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                            />
                                            <Typography style={{ fontSize: '10px', color: '#142A30', fontWeight: 700 }}>
                                                Consulta de recebíveis
                                            </Typography>
                                        </>
                                        : null}
                                </Grid>
                            </Grid>

                            {isMobile ? selectedFilter() : null}

                        </Grid>

                        {filtersSelect()}

                    </Grid>
                </Grid>

                <Grid sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '35px' }}>

                    <Grid sx={styles.bigValues}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                                Valor total
                            </Typography>
                            <Typography sx={{ fontSize: calcMimMax(14, 20, 100), fontWeight: 600, color: '#142A3099', marginTop: '-8px' }}>
                                Depositado
                            </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: calcMimMax(22, 40, 40 + formatCurrency(valuesFiltered.RecebidoAteHoje).length), fontWeight: 700, color: '#142A30' }}>
                            {valuesFiltered.RecebidoAteHoje ? formatCurrency(valuesFiltered.RecebidoAteHoje) : 'R$ 0,00'}
                        </Typography>
                    </Grid>
                    <Grid sx={styles.bigValues}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                                Valor total
                            </Typography>
                            <Typography sx={{ fontSize: calcMimMax(14, 20, 100), fontWeight: 600, color: '#142A3099', marginTop: '-8px' }}>
                                Taxa
                            </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: calcMimMax(22, 40, 40 + formatCurrency(valuesFiltered.Valor).length), fontWeight: 700, color: '#142A30' }}>
                            {valuesFiltered.TaxaTotal ? formatCurrency(valuesFiltered.TaxaTotal) : 'R$ 0,00'}
                            {/* {valuesFiltered.Valor ? formatCurrency(valuesFiltered.Valor - valuesFiltered.ValorBruto) : 'R$ 0,00'} */}
                        </Typography>
                    </Grid>
                    <Grid sx={styles.bigValues}>
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                                Valor total a
                            </Typography>
                            <Typography sx={{ fontSize: calcMimMax(14, 20, 100), fontWeight: 600, color: '#142A3099', marginTop: '-8px' }}>
                                Depositar
                            </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: calcMimMax(22, 40, 40 + formatCurrency(valuesFiltered.VouReceber).length), fontWeight: 700, color: '#142A30' }}>
                            {valuesFiltered.VouReceber ? formatCurrency(valuesFiltered.VouReceber) : 'R$ 0,00'}
                        </Typography>
                    </Grid>

                </Grid>

                <Grid sx={{ border: '1.5px solid #142A3066', marginBottom: '28px' }} />

                <Grid sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: screenWidth <= 1450 ? 'column-reverse' : 'row',
                    gap: '20px'
                }}>

                    <Grid sx={{
                        maxHeight: '662px',
                        width: screenWidth <= 1450 ? '100%' : '33%',
                        display: selectedItem?.items?.length > 0 ? 'none' : 'block',
                    }}>

                        <Grid sx={{
                            gap: '15px',
                            display: 'flex',
                            paddingBottom: '15px',
                            flexDirection: screenWidth <= 780 ? 'column' : screenWidth <= 1450 ? 'row' : 'column',
                        }}>

                            <Grid sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: screenWidth <= 780 ? '100%' : screenWidth <= 1450 ? '48%' : null,
                                paddingBottom: screenWidth <= 1450 ? null : '15px'
                            }}>
                                <Typography sx={{ fontSize: '24px', color: '#142A30', paddingBottom: '15px', fontWeight: 700 }}>
                                    Lançamentos no período
                                </Typography>
                                <Grid sx={{
                                    padding: '22px 25px',
                                    border: '2px solid #142A3066',
                                    borderRadius: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '-webkit-fill-available'
                                }}>

                                    {tipoLançamento.map((i) => (
                                        <Grid
                                            key={i.filterValue}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                borderBottom: '1px solid #F1F1F1',
                                                justifyContent: 'space-between',
                                                padding: '5px',
                                                marginBottom: '10px',
                                                cursor: 'pointer',
                                                bgcolor: i.filterValue === selectedFormaPagamento || i.filterValue === selectedFormaPagamentoParcelado ? '#F0EFEB' : null
                                            }}
                                            onClick={() => handleFormaPagamentoChange(i)}  // Passa o objeto direto
                                        >
                                            <Typography sx={{ fontSize: '16px', alignItems: 'center', color: '#142A30', fontWeight: 400 }}>
                                                {i.label}
                                            </Typography>
                                            <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 700, paddingRight: '9px', alignItems: 'center' }}>
                                                    {formatCurrency(i.value)}
                                                </Typography>
                                                {i.label === 'Crédito parcelado' ? (
                                                    i.filterValue === selectedFormaPagamentoParcelado ? (
                                                        <FontAwesomeIcon
                                                            icon={faXmark}
                                                            style={{ width: '16px', height: '16px', color: "#142A30", paddingRight: '3px' }}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faChevronRight}
                                                            style={{ width: '16px', height: '16px', color: "#142A30", paddingRight: '3px' }}
                                                        />
                                                    )
                                                ) : (
                                                    i.filterValue === selectedFormaPagamento ? (
                                                        <FontAwesomeIcon
                                                            icon={faXmark}
                                                            style={{ width: '16px', height: '16px', color: "#142A30", paddingRight: '3px' }}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faChevronRight}
                                                            style={{ width: '16px', height: '16px', color: "#142A30", paddingRight: '3px' }}
                                                        />
                                                    )
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}

                                </Grid>
                            </Grid>

                            {/* <Grid sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: screenWidth <= 780 ? '100%' : screenWidth <= 1450 ? '50%' : null
                            }}>
                                <Typography sx={{ width: screenWidth <= 780 ? '100%' : '20ch', fontSize: '24px', color: '#142A30', paddingBottom: '15px', fontWeight: 700 }}>
                                    Conta cadastrada para depósito de fundos:
                                </Typography>
                                <Grid sx={{
                                    padding: '22px 25px',
                                    border: '2px solid #142A3066',
                                    borderRadius: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '-webkit-fill-available'
                                }}>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}>

                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '25px' }}>
                                            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A30', alignItems: 'center' }}>
                                                    Bancoob S.A.
                                                </Typography>
                                                <Typography sx={{ fontSize: '16px', color: '#142A3099', alignItems: 'center' }}>
                                                    Ag.: 0001 | Cc.:  22700847-2
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#142A30', alignItems: 'center' }}>
                                                    Valor depositado:
                                                </Typography>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 700, color: '#142A30', alignItems: 'center' }}>
                                                    {valuesFiltered.RecebidoAteHoje ? formatCurrency(valuesFiltered.RecebidoAteHoje) : 'R$ 0,00'}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Button sx={{
                                            fontWeight: 700,
                                            maxWidth: '150px',
                                            textTransform: 'none',
                                            fontSize: '15px',
                                            color: '#142A30',
                                            bgcolor: '#F0EFEB',
                                            borderRadius: '15px'
                                        }}>
                                            Detalhar valores
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid> */}

                        </Grid>

                        <Typography sx={{ fontSize: '15px', color: '#142A3099', alignItems: 'center', paddingBottom: '10px' }}>
                            Caso tenha alguma dúvida ou esteja com algum problema, entre em contato com o nosso suporte.
                        </Typography>

                        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Grid sx={{
                                width: '15px',
                                height: '15px',
                                display: 'flex',
                                bgcolor: '#142A30',
                                borderRadius: '20px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '5px',
                                marginRight: '6px'
                            }}>
                                <FontAwesomeIcon
                                    icon={faQuestion}
                                    style={{ width: '10px', height: '10px', color: "#FFFFFF", cursor: 'pointer', }}
                                />
                            </Grid>
                            <Typography sx={{ fontSize: '10px', fontWeight: 600, color: '#142A3099', alignItems: 'center' }}>
                                Central de ajuda
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{
                        width: screenWidth <= 1450 ? '100%' : selectedItem?.items?.length > 0 ? '100%' : '66%',
                        // height: '710px',
                        // height: '662px',
                        bgcolor: '#F0EFEB',
                        borderRadius: '20px'
                    }}>

                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: ' 0px 20px 0px 0px'
                        }}>
                            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                                {selectedItem?.items?.length > 0 ?
                                    <Button onClick={() => { setSelectedItem({}); setSelectedItemValor({}) }}>
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            style={{
                                                color: "#9DE200",
                                                cursor: 'pointer',
                                                paddingRight: '3px',
                                                padding: '5px',
                                                borderRadius: '20px',
                                                backgroundColor: '#17333A',
                                                width: '15px',
                                                height: '15px',
                                            }}
                                        />
                                    </Button> : null
                                }
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        color: '#142A30',
                                        padding: selectedItem?.items?.length > 0 ? '31px 40px 31px 0px' : '31px 40px',
                                        fontWeight: 700
                                    }}>
                                    Consulta de recebíveis
                                </Typography>
                            </Grid>
                            {selectedItemValor?.items?.length > 0 ?
                                <Grid sx={{
                                    height: '35px',
                                    display: 'flex',
                                    bgcolor: '#FFFFFF',
                                    padding: '5px 10px',
                                    borderRadius: '10px',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    border: '1px solid #142A3066'
                                }}>
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        style={{
                                            width: '15px',
                                            height: '15px',
                                            color: "#142A30"
                                        }}
                                    />
                                    <OutlinedInput
                                        sx={{
                                            ...styles.customInputCod,
                                            '& .MuiInputBase-input': {
                                                color: '#142A30'
                                            },
                                            '& .MuiInputBase-input::placeholder': {
                                                color: '#142A30',
                                                opacity: 1
                                            },
                                            width: '100%',
                                            maxWidth: '280px'
                                        }}
                                        placeholder="Procurar valor bruto ou ID"
                                        // value={formatCurrency(valorBrutoFilter)}
                                        value={valorBrutoFilter}
                                        onChange={handleValorBruto}
                                    />
                                </Grid> : null}
                        </Grid>

                        {/* {console.log('selectedItemValor', selectedItemValor[0]idvenda)} */}

                        <Grid sx={{ border: '3px solid #142A30', marginBottom: '20px' }} />

                        <Grid sx={{ width: '100%' }}>
                            <TabelaGroup
                                hoje={hoje}
                                agora={agora}
                                isMobile={isMobile}
                                rows={filteredRows ? filteredRows : rows}
                                selectedItem={selectedItemValor ? selectedItemValor : selectedItem}
                                setSelectedItem={setSelectedItem}
                                setSelectedItemValor={setSelectedItemValor} />
                        </Grid>

                    </Grid>

                </Grid>

            </Grid>
        </Grid >
    );
};

export default Resumo;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '40px 25px'
    },
    contentMobile: {
        margin: '10px 10px 20px 10px'
    },
    customSeparator: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '23px'
    },
    customInputNumber: {
        height: '45px',
        borderRadius: '15px',
        border: '1px solid #2F4650',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: '#142A3099',
            fontWeight: 'bold',
            padding: '15px 17px',
        },
        '& .MuiInputBase-input::placeholder': {
            color: '#142A3099',
            opacity: 1,
            fontWeight: 'bold',
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    customInputCod: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    gridInput: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '30%',
        maxWidth: '100%',
        minWidth: '150px',
    },
    bigValues: {
        flexGrow: 1,
        flexShrink: 1,
        height: '88px',
        flexBasis: '30%',
        maxWidth: '100%',
        minWidth: '240px',
        bgcolor: '#F0EFEB',
        // padding: '0px 35px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        borderRadius: '20px'
    }
})